<script>
  import userFSDocumentMixin from '@/mixins/userFSDocumentMixin.js';
  import { mapActions, mapMutations } from 'vuex';
  import AppBar from '@/components/general/app-bar/AppBar.vue';

  export default {
    mixins: [userFSDocumentMixin],
    name: 'change-email',
    data() {
      return {
        email: '',
        isChangingEmail: false,
      };
    },
    components: {
      AppBar,
    },
    async created() {
      await this.fetchUser();
      this.prefillEmailField();
    },
    computed: {
      isEmailDisabled() {
        return !this.email?.length;
      },
    },
    methods: {
      ...mapMutations(['setSuccessSnackbarMessage', 'setErrorSnackbarMessage']),
      ...mapActions('AuthModule', ['changeEmailAddress']),
      confirmChangeEmail() {
        this.$alertsService.confirmAction(
          'Are you sure you want to update this email? You will be logged out after!',
          this.changeEmail
        );
      },
      async changeEmail() {
        this.isChangingEmail = true;

        try {
          await this.changeEmailAddress(this.email);
          this.setSuccessSnackbarMessage('Email successfully updated');
          // Log out after email update
          this.logout();
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isChangingEmail = false;
        }
      },
      prefillEmailField() {
        this.email = this.userFSDocument.email;
      },
      logout() {
        this.$router.push({
          name: 'Logout',
        });
      },
    },
  };
</script>
